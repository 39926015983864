/* Default styles for larger devices */
.Blog-container {
    display: flex;
    flex-direction: row; /* Change to row layout for side-by-side content */
    justify-content: space-between; /* Space between content and image */
    margin: 20px;
}

.Blog-img {
    width: 40%; /* Adjust image width */
    height: auto;
}

.Full-width-img {
    width: 100%;
    height: auto;
}

.Blog-Content {
    width: 55%; /* Adjust content width */
    padding: 20px;
    text-align: left;
    margin-right: 20px; /* Add spacing between content and image */
}

.Author-container {
    position: absolute;
    top: 60vh;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40vh;
    height: 40vh;
}

.author-image img {
    width: 40vh;
    height: 40vh;
}

.author-name {
    text-align: center;
}

/* Styles for small devices (e.g., smartphones) */
@media screen and (max-width: 480px) {
    .Blog-container {
        flex-direction: column; /* Stack content and image vertically on small screens */
        margin: 10px;
    }

    .Blog-img,
    .Full-width-img {
        height: 30vh;
        width: 100%;
    }

    .Blog-Content {
        width: 100%; /* Use full width for content on small screens */
        padding: 10px;
    }

    .Author-container {
        display: none;
    }
}
