
.container-1{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 100px;
}

.container-1:after{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  /* background: url("https://i.ibb.co/z4wgBG3/bgg-min.jpg") no-repeat center; */
  background-size: cover;
  /* filter: blur(50px); */
  z-index: -1;
}
.contact-box-1{
  max-width: 850px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 0px 19px 5px rgba(0,0,0,0.19);
}

.left-1{
  background-size: cover;
  height: 100%;
}

.right-1{
  padding: 25px 40px;
}

.h2-1{
  position: relative;
  padding: 0 0 10px;
  margin-bottom: 10px;
}

.h2-1:after{
  content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    height: 4px;
    width: 50px;
    border-radius: 2px;
    background-color: #0c0e0d;
}

.field{
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0);
  outline: none;
  background-color: rgba(230, 230, 230, 0.6);
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  margin-bottom: 22px;
  transition: .3s;
}

.field:hover{
  background-color: rgba(0, 0, 0, 0.1);
}

textarea{
  min-height: 150px;
}



.field:focus{
    border: 2px solid rgba(108, 108, 108, 0.47);
    background-color: #fff;
}

@media screen and (max-width: 880px){
  .contact-box-1{
    grid-template-columns: 1fr;
  }
  .left-1{
    height: 200px;
  }
}