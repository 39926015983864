/* Default styles for larger devices */

/* .Ser-first-div Section */
.Ser-first-div {
  position: relative;
  width: 100vw;
  height: 30vh;
  overflow: hidden;
}

.Ser-first-text-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  color: white;
}

.Ser-first-div-image {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  margin: 0;
}


/* Styles for small devices (e.g., smartphones) */
@media screen and (max-width: 768px) {
  /* .Ser-first-div Section */
  .Ser-first-div {
    height: 50vh; /* Adjust the height for small devices as needed */
  }

  .Ser-first-text-container {
    padding: 5px; /* Adjust the padding for spacing on small devices */
  }

  .Ser-first-div-image {
    max-height: 50vh; /* Adjust the max-height for the image on small devices */
  }

}

  
.Card-ser{
    width: 100vw;
    height: 80vh;
    border: 2px solid black;
}

.LinkStyle {
  text-decoration: none; /* Remove underline by default */
  color: black; /* Set the default text color */
}

/* Style the Link component when hovered */
.LinkStyle:hover {
  text-decoration: none; /* Add underline on hover */
  color: blue; /* Change text color to blue on hover */
}

.card-row {
  display: flex; /* Use Flexbox */
  flex-wrap: wrap; /* Wrap cards to the next row if they overflow */
  justify-content: space-between; /* Space between cards */
  gap: 20px; /* Adjust the gap between cards */
}

.card-1 {
  /* Style for each card */
  width: calc(33.33% - 20px); /* Adjust the card width and gap */
  /* Add other styling as needed */


  /* Responsive styles for larger devices (e.g., desktops) */
  @media (min-width: 768px) {
    width: calc(25% - 20px); /* Adjust card width for larger screens */
  }

  /* Responsive styles for smaller devices (e.g., mobile) */
  @media (max-width: 576px) {
    width: calc(50% - 20px); /* Adjust card width for smaller screens */
  }
}

.typing-text {
  text-align: center;
  margin-top: 50px;
}

.typing-text h1 {
  font-size: 2em;
  white-space: nowrap;
  overflow: hidden;
  border-right: 2px solid #333; /* Adjust the border properties as needed */
  padding-right: 10px; /* Adjust the padding as needed */
  display: inline-block;
}

.typing-text h1 span {
  display: inline-block;
  width: 0;
  animation: typing 6s steps(20, end) infinite; /* Add infinite iteration */
  color: rgb(233, 0, 0);
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

