.navbar {
  background: linear-gradient(90deg, rgba(251,250,255,1) 0%, rgba(231,231,231,1) 100%, rgba(179,179,200,1) 100%);
}

.navbar-logo { 
  width: 20%;
  aspect-ratio: 4/3;
  object-fit: contain;
  height: 8vh;
  font-weight: bold;
  background-color: transparent;
  margin-left: -40px;
 
}

/* Styles for small devices (e.g., smartphones) */
@media screen and (max-width: 768px) {
  .navbar-logo {
    width: 40%; /* Adjust the width for small devices */
    height: auto; /* Allow the height to adjust based on aspect ratio */
    margin-left: 0; /* Remove margin for small devices if needed */
  }
}

.list-items {
  margin: 10px;
  color: black;
  font-family: 'Poppins', sans-serif;
  font-size: medium;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-decoration: none;
  --b: 0.1em;
  --c: #1095c1;
  color: #0000;
  padding-block: var(--b);
  background:
    linear-gradient(var(--c) 50%, #000 0) 0% calc(100% - var(--_p, 0%))/100% 200%,
    linear-gradient(var(--c) 0 0) 0% var(--_p, 0%)/var(--_p, 0%) var(--b) no-repeat;
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
  transition: .3s var(--_s, 0s) linear, background-size .3s calc(.3s - var(--_s, 0s));
}

.list-items:hover {
  --_p: 100%;
  --_s: .3s;
}




