/* Navbar.css */
.Admin-navbar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 50px;
    background: linear-gradient(90deg, #22CAB8 -0.9%, #108A7D 100%);;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    z-index: 999;
    margin: 0;
}

.right-section {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    border: 5px solid rgb(255, 253, 253);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 0.6rem;
    z-index: 1;
    right: -0px;
    top: 100%;
}

.dropdown-content p {
    color: black;
    padding: 5px 0;
    text-decoration: none;
    display: block;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

.round-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.horizontal-line {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0px 0;
}
.anchorTagFor{
    width: 100%;
    display: flex;
    align-items: start;
}