body {
  font-family: 'Poppins', sans-serif;
}

.First {
  display: flex;
  flex-direction: row;
  border-radius: 2px;
}
.sec1 {display: flex; flex-direction: column; justify-content: center; height: 70vh; width: 50%; margin-left: 80px;}
.sec1 h1 {margin-bottom: 5px;}
.sec1 p {margin-top: 10px;}
.button-container {display: flex;}
.button-container button {margin-right: 10px;}
.sec1 button {margin-top: 15px;}
.sec1 {
  animation: revealText 5s forwards; /* Animation to reveal text */
}

@keyframes revealText {
  from {
    text-indent: -100%;
  }
  to {
    text-indent: 0;
  }
}


.sec2 {height: 60vh; width: 50%; margin-top: 50px; margin-left: 90px;}
.First-logo {max-width: 100%; max-height: 100%; object-fit: contain;}

.sec2 {
  animation: pulse 15s 1;
}

@keyframes pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.2); }
}



/* Media Query for mobile screens */
@media only screen and (max-width: 767px) {
  .First {
    flex-direction: column;
    align-items: center;
  }

  .sec1 {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
  }

  .sec2 {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
  }
}



/* Your existing CSS */

/* Unique styles for h1 elements */
.h1-one {
  font-size: 24px;
  color: hsl(234, 12%, 34%);
  margin-top: 20px;
  text-align: center;
  font-weight: 200;
}

.h1-two {
  font-size: 20px;
  color: hsl(234, 12%, 34%);
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
}

/* Unique styles for p element */
.paragraph {
  font-size: 15px;
  font-weight: 400;
  color: hsl(229, 6%, 66%);
  width: 500px;
  margin: auto;
  text-align: center;
  margin-bottom: 40px;
}

/* Unique styles for div with class "card-container" */
.card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  width: 1000px;
  height: 440px;
  margin: auto;
  padding-left: 20px;
}

/* Unique styles for div with class "unique-div" */
.unique-div {
  border-radius: 5px;
  width: 300px;
  height: 200px;
  position: relative;
  box-shadow: 0px 0px 10px 5px hsl(229deg 6% 66% / 20%);
  color: hsl(234, 12%, 34%);
  background-color: #fff;
}

/* Unique styles for h3 elements within div with class "unique-div" */
.unique-div h3 {
  margin: 20px 15px 10px 25px;
  color: hsl(234, 12%, 34%);
}

/* Unique styles for p elements within div with class "unique-div" */
.unique-div p {
  font-size: smaller;
  color: hsl(229, 6%, 66%);
  margin-left: 25px;
  margin-right: 25px;
}

/* Unique styles for img elements with specific class names */
.super-img {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 50px;
}

.team-img {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 50px;
}

.karma-img {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 50px;
}

.calc-img {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 50px;
}

/* Unique styles for specific div classes */
.super-div {
  border-top: 4px solid hsl(180, 62%, 55%);
  grid-area: 2/1/span 2/1;
}

.team-div {
  border-top: 4px solid hsl(0, 78%, 62%);
  grid-area: 1/2/span 2/2;
}

.karma-div {
  border-top: 4px solid hsl(34, 97%, 64%);
  grid-area: 3/2/span 2/2;
}

.calc-div {
  border-top: 4px solid hsl(212, 86%, 64%);
  grid-area: 2/3/span 2/3;
}


/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .card-container {
    display: block; /* Show cards in a single column */
  }

  .unique-div {
    width: 30%; /* Occupy full width */
    margin-bottom: 20px; /* Add space between cards */
    text-align: center; /* Center text */
  }

  .unique-div h3,
  .unique-div p {
    margin-bottom: 10px; /* Add space between heading and paragraph */
  }

  .unique-div img {
    display: block; /* Display image below the text */
    margin: 0 auto; /* Center image */
  }
}


/* ==========2nd Image================ */
.Second {
  display: flex;
  flex-direction: row-reverse;
  border-radius: 2px;
}
.sec3 {display: flex; flex-direction: column; justify-content: center; height: 70vh; width: 50%; margin-left: 80px;}
.sec3 h1 {margin-bottom: 5px;}
.sec3 p {margin-top: 10px;}
.button-container {display: flex;}
.button-container button {margin-right: 10px;}
.sec3 button {margin-top: 15px;}

.sec4 {height: 60vh; width: 50%; margin-top: 50px; margin-left: 90px;}
.Second-logo {max-width: 100%; max-height: 100%; object-fit: contain;}




/* Media Query for mobile screens */
@media only screen and (max-width: 767px) {
  .Second {flex-direction: column; align-items: center;} /* Align items to the center */
  .sec3 {width: 80%; margin: 0 auto; margin-top: 360px;} /* Adjust section width and margins */
  .sec4 {width: 80%; margin: 0 auto; margin-top: 20px;} /* Adjust section width and margins */
}


/* ===========last cards ============= */
.Last-div {
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap to the next row on smaller screens */
  justify-content: center;
}

.snip1347 {
  font-family: 'Roboto', Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 230px;
  max-width: 315px;
  width: 100%;
  color: #ffffff;
  text-align: left;
  line-height: 1.4em;
  background-color: #141414;
}

.snip1347 img {
  max-width: 100%;
  vertical-align: top;
  opacity: 0.85;
}

.snip1347 .date {
  position: absolute;
  background-color: #1e1e1e;
  top: 0;
  right: 0;
  width: 100%;
  padding: 10px 25px 0;
  text-align: right;
  font-size: 0.8em;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
}

.snip1347 .date:before {
  position: absolute;
  content: '';
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 55px 0 0 400px;
  border-color: transparent transparent transparent #141414;
}

.snip1347 figcaption {
  width: 100%;
  background-color: #141414;
  padding: 0 25px 25px;
  position: relative;
}

.snip1347 figcaption:before {
  position: absolute;
  content: '';
  bottom: 100%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 55px 0 0 400px;
  border-color: transparent transparent transparent #141414;
}

.snip1347 figcaption a {
  padding: 5px;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 0.7em;
  text-transform: uppercase;
  text-decoration: none;
  margin: 10px 0;
  display: inline-block;
  opacity: 0.65;
  width: 47%;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
}

.snip1347 figcaption a:hover {
  opacity: 1;
}

.snip1347 h2 {
  margin: 0 0 10px;
  font-weight: 300;
  font-size: 1.5em;
  line-height: 1.2em;
}

.snip1347 p {
  margin: 0 0 10px;
  font-size: 0.8em;
  letter-spacing: 1px;
  opacity: 0.8;
}

.truncate-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fixed-size-image {
  width: 100%;
  height: auto;
  max-height: 200px;
}

/* Media queries for responsiveness */

@media screen and (max-width: 600px) {
  .snip1347 {
    max-width: 100%; /* Adjust the maximum width for smaller screens */
  }
}

button {
  position: relative;
  margin: 0;
  padding: 0.4em 0.6em;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  background-color: #333;
  border-radius: 8px;
  color: #fff;
  font-weight: 300;
  font-size: 16px;
  font-family: inherit;
  z-index: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
}

button:hover {
  animation: sh0 0.5s ease-in-out both;
}

@keyframes sh0 {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  25% {
    transform: rotate(7deg) translate3d(0, 0, 0);
  }

  50% {
    transform: rotate(-7deg) translate3d(0, 0, 0);
  }

  75% {
    transform: rotate(1deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

button:hover span {
  animation: storm 0.7s ease-in-out both;
  animation-delay: 0.06s;
}

button::before,
button::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #fff;
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.02, 0.01, 0.47, 1), opacity 0.15s cubic-bezier(0.02, 0.01, 0.47, 1);
  z-index: -1;
  transform: translate(100%, -25%) translate3d(0, 0, 0);
}

button:hover::before,
button:hover::after {
  opacity: 0.15;
  transition: transform 0.2s cubic-bezier(0.02, 0.01, 0.47, 1), opacity 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
}

button:hover::before {
  transform: translate3d(50%, 0, 0) scale(0.9);
}

button:hover::after {
  transform: translate(50%, 0) scale(1.1);
}

