/* Sidebar.css */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

.sidebar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 17%;
    height: 100%; /* Set height to 100% */
    z-index: 99;
    background-color: #161528;
    padding-top: 30px; /* Increase padding-top to increase height */
    font-family: 'Visby Round CF', sans-serif;
    z-index: 1000;
}

.crossSign{
    position: absolute;
    top:15px;
    right:12px;
    font-size: 20px;
}

.sidenav {
    position: relative;
    overflow-x: hidden;
}


.sidenav p,
.dropdown-btn {
    padding: 8px 20px; 
    text-decoration: none;
    font-size: 16px;
    color: #818181;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
    margin-bottom: 10px; 
 
}

.sidenav p i,
.dropdown-btn i {
    margin-right: 5px; /* Adjusted margin for more space between icon and text */
}

.sidenav p:hover,
.dropdown-btn:hover {
    color: #f1f1f1;
}

.main {
    margin-left: 250px; /* Adjusted the margin to match the sidebar width */
    font-size: 20px; /* Increased text to enable scrolling */
    /* padding: 20px; Adjusted padding for content */
}

.Admin-active {
    background-color: #282238;
    color: white;
}

.dropdown-container {
    display: none;
    background-color: #161528;
    padding-left: 30px;
    list-style-type: none; /* Remove default bullets */
    margin-top: -10px;
}

.dropdown-container p {
   display: block;
    position: relative;
    margin-bottom: -2px; /* Add space between items */
}



.fa-caret-down {
    float: right;
    padding-right: 8px;
}

.open {
    display: block;
}

.Heading-sidebar {
    font-size: 2.1rem;
    font-weight: bolder;
    color: #22CAB8;
    padding: 15px;
    display: block;
    text-align: center;
}


.version1 {
    font-weight: bold;
    color: #22CAB8;
    width: 100%;  position: absolute;
    bottom: 1rem;
  }
  


@media screen and (min-width:640px) and (max-width: 916px) {
    .sidebar-container {
        width: 30%;
    }
}

@media  screen and (min-width: 917px) and (max-width: 1081px) {
    .sidebar-container{
        width: 20%;
    }
}

@media screen and (min-width:400px) and (max-width: 639px) {
    .sidebar-container {
        width: 50%;
    }
}


@media screen and (max-width: 400px) {
    .sidebar-container {
        width: 80%;
    }
}

