/* Default styles for larger devices */

.blog-card {
  max-width: 90%;
  width: 100%;
  height: auto;
  position: relative;
  color: black;
  margin: 20px auto;
  border-radius: 0px;
  box-shadow: 0px 10px 20px -9px rgba(0, 0, 0, 0.5);
  text-align: center;
  transition: all 0.4s;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 120%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.blog-image {
  flex: 1;
  text-align: left;
  padding-left: 10px;
}

.blog-content {
  flex: 2;
  text-align: center;
}

.blog-date {
  flex: 1;
  text-align: right;
  padding-right: 10px;
}

.blog-card h4 {
  font-size: 1.5rem;
  margin: 10px 0;
}

.blog-card p {
  font-size: 1rem;
  margin: 5px 0;
}

.blog-card a {
  color: #fff;
  text-decoration: none;
  transition: all 0.2s;
}

.blog-card a:hover {
  text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.95);
}

.blog-card .licon {
  position: relative;
  width: 23px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  color: black;
}

.blog-card .licon:before {
  content: "";
  background: url(https://rawcdn.githack.com/Nodws/NodPen/ffad95aa5244b4b09a3c7c1508a018959bbedb7e/postItem/licons.svg) -2px -6px no-repeat;
  background-size: 250px;
  width: 26px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: -3px;
  left: 0;
  color: black;
}

.blog-card .read-more {
  color: #FF6600;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* Styles for small devices (e.g., smartphones) */
@media screen and (max-width: 768px) {
  .blog-card {
    flex-direction: column; /* Stack items vertically on smaller screens */
    max-width: 100%; /* Adjust the width for small devices */
  }

  .blog-image,
  .blog-content,
  .blog-date {
    flex: 1; /* Equal width for all sections on small devices */
    text-align: center; /* Center align text on small devices */
    padding: 5px; /* Adjust spacing for small devices */
  }

  .blog-card h4 {
    font-size: 1.2rem; /* Adjust the font size for small devices */
  }

  .blog-card p {
    font-size: 0.9rem; /* Adjust the font size for small devices */
  }
}
